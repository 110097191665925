<template>
    <div class="main">
        <Title class="title">【我为群众办实事】福建历思司法鉴定所为市民提供司法鉴定公益服务</Title>
        <p class="time">2019-07-11 11:31</p>
        <Pgp>
            7月11日，福建历思司法鉴定所的志愿者们来到厦门中山路公益法律服务驿站参加“公证与司法鉴定行业公益服务惠民日”公共法律服务志愿服务活动暨新未成年人保护法和预防未成年人犯罪法宣传教育活动，为市民提供司法鉴定公益服务。
        </Pgp>
        <ImagePreview  class="more_img" :src="require('../../assets/Duty/public/1-1.jpg')" :srcList="[require('../../assets/Duty/public/1-1.jpg')]"></ImagePreview>
        <Pgp>本次活动由厦门市司法局公共法律服务处组织，目的是为了将“我为群众办实事”实践活动落到实处，充分用好用活“中山路公益法律服务驿站”这一公共法律服务窗口，增强机关干部职工、公证人、司法鉴定人等公共法律服务工作者的责任意识，在实践中提升服务能力和水平。同时，加强对新修订的《未成年人保护法》和《预防未成年人犯罪法》（以下简称“两法”)的宣传，提高“两法”的社会知晓度和影响力，营造“学法懂法知法守法”的社会氛围。<span style="color:#D9001B">活动共有四大项内容：公证与司法鉴定业务咨询、法律知识有奖问答、公共法律服务普法知识宣传、最新修订的《中华人民共和国未成年人保护法》和《中华人民共和国预防未成年人犯罪法》的宣传。</span></Pgp>


        <div class="img-box">
            <ImagePreview v-for="img in img_data" :key="img" class="img" :src="require('../../assets/Duty/public'+img)" :srcList="[require('../../assets/Duty/public'+img)]"></ImagePreview>
        </div>
        <Title class="title2">活动现场</Title>
        <Pgp>在厦门市司法局公共法律服务处赖敬佩处长的带领下，市局机关和福建历思司法鉴定所、开元公证处的志愿者们积极投入到本次志愿服务活动中。在现场，福建历思司法鉴定所志愿者们为广大市民和游客介绍司法鉴定业务范围，解答大家在相关方面的疑问；在有奖问答环节，志愿者们调动大家参与活动的积极性，在富有趣味性的互动中，普及司法鉴定相关知识。
        </Pgp>
        <ImagePreview  class="more_img" :src="require('../../assets/Duty/public/1-3.jpg')" :srcList="[require('../../assets/Duty/public/1-3.jpg')]"></ImagePreview>
        <Pgp>贴近生活的普法活动广受欢迎，志愿者们热情洋溢的服务也得到了大家的赞赏。未来，福建历思司法鉴定所将继续增强社会责任意识，积极参与“我为群众办实事”的实践活动；不断提升能力和水平，为广大市民提供更优质的司法鉴定服务。</Pgp>
        <div class="line"></div>
        <div class="footer">
            <div class="left">
                <p class="item">上一篇：无</p>
                <p class="item">下一篇：<router-link :to="'/duty/public_detail/2'">疫情防控，历思公益在行动！...</router-link></p>
            </div>
            <div class="right">
                <el-button type="primary" @click="goBack">返回列表</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            img_data:['/1-2.jpg','/1-3.jpg']
        }
    },
    mounted(){
        console.log(this.$route)
        // switch(this.id){
        //     case '1':

        //     break;
        // }

    },
    computed:{
        id(){
            return this.$route.params.id
        },
    },
    methods:{
        goBack(){
            this.$store.commit('set_path',{path:'/duty/public',query:{}})
        }
    }
}
</script>
<style scoped src="./publicDetail.css"></style>
<style scoped>